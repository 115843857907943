<template>
  <AppMainContainer>
    <template #center>
      высоту и ширину проставлять необходимо самому.
      <div>
        <AppInput
          v-model="form.text"
          placeholder="Иконка слева"
        >
          <template #leftIcon>
            <IconAttachFile />
          </template>
        </AppInput>
      </div>
      <div>
        <AppInput
          v-model="form.text"
          placeholder="Иконка слева"
        >
          <template #leftIcon>
            <IconAttachFile />
          </template>
          <template #unit>
            руб/кв2
          </template>
        </AppInput>
      </div>

      <div>
        <AppInput
          v-model="form.text"
          placeholder="Иконка справа"
        >
          <template #rightIcon>
            <IconEmail
              width="30"
              height="30"
            />
          </template>
        </AppInput>
      </div>
      <div>
        <AppInput
          v-model="form.text"
          placeholder="Иконка справа"
        >
          <template #rightIcon>
            <IconChat
              width="30"
              height="30"
            />
          </template>
        </AppInput>
      </div>

      <div>
        <AppInput
          v-model="form.serverValidation"
          :key-input="'address'"
          :server-validation="serverValidation"
          placeholder="Проверка названия"
        />
        <AppInput
          v-model="form.serverValidation"
          :key-input="'address'"
          :server-validation="serverValidation"
          placeholder="Проверка адреса"
        />
        <app-button @click.native="serverValidTest">
          Отправить запрос
        </app-button>
      </div>
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import AppInput from '@/components/common/simple/AppInput'
import IconAttachFile from '@/components/common/icons/IconAttachFile'
import IconChat from '@/components/common/icons/IconChat'
import { validateEmail } from '@/utils/validation'
import AppButton from '@/components/common/simple/AppButton'
import IconEmail from '@/components/common/icons/IconEmail'

export default {
  name: 'PageExample',
  components: { IconEmail, AppButton, IconChat, IconAttachFile, AppInput, AppMainContainer },
  data () {
    return {
      form: {
        test: '',
        serverValidation: '',
        organization: {
          id: 'd9ef5e12-2250-4caf-8a99-7f7b71dd58f9',
        }
      },
      serverValidation: null,
      validationFields: {
        serverValidation: [
          {
            text: 'Электронная почта формата example@mail.com',
            valid: true,
            validator: (value) => validateEmail(value)
          }
        ],
      },
    }
  },
  methods: {
    serverValidTest () {
      return this.$store.dispatch('office/fetchOfficeCreate', this.form)
        .catch((error) => {
          if (error.data) this.serverValidation = error.data.violations
        })
    }
  }
}
</script>

<style scoped>

</style>
