<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M28 14.1129L15.5359 26.6649C12.443 29.7783 7.41191 29.7783 4.31979 26.6649C1.22674 23.5501 1.22674 18.4831 4.31979 15.3682L16.7828 2.81766C19.1877 0.394114 23.1015 0.394114 25.508 2.81766C27.9124 5.23927 27.9124 9.1813 25.508 11.603L15.5565 21.6224C14.7277 22.4701 13.6203 22.9382 12.4369 22.9432C10.0729 22.9537 8.00467 20.8737 8.01333 18.4882C8.01752 17.2991 8.48503 16.1828 9.32497 15.3475L19.2753 5.32766L20.5214 6.58263L10.5507 16.6232C10.054 17.1243 9.77854 17.7859 9.77598 18.4953C9.76988 19.9255 11.0101 21.1749 12.4301 21.1684C13.1333 21.1665 13.7934 20.8892 14.2898 20.3882L24.2616 10.3478C25.9795 8.61786 25.9795 5.80272 24.2616 4.07245C22.542 2.34254 19.747 2.34254 18.0292 4.07245L5.56593 16.6232C3.16048 19.0447 3.16048 22.9867 5.56593 25.4101C7.97056 27.8319 11.8845 27.8319 14.2898 25.4101L26.7542 12.8579L28 14.1129Z" fill="black"/>
  </svg>

</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconAttachFile",
        mixins: [icon_config],
    }
</script>

<style lang="sass" scoped>

</style>
