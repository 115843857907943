<template>
  <svg :width="width ? width : 100" :height="height ? height : 88" viewBox="0 0 100 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path d="M24.2424 21.2242H4.0404C1.80895 21.2242 0 23.0357 0 25.2702V71.7547C0 73.9892 1.80895 75.8007 4.0404 75.8007H16.1616V87.2748C16.1616 87.9075 16.9153 88.2356 17.3773 87.8041L30.2309 75.8007H71.7172C73.9486 75.8007 75.7576 73.9892 75.7576 71.7547V60.169L71.7172 56.3958V71.7547H28.6392L20.202 79.6339V71.7547H4.0404V25.2702H24.2424V21.2242Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M71.3606 50.5305L79.798 58.4098V50.5305H95.9596V4.04596L28.2828 4.04596V50.5305H71.3606ZM83.8384 66.0507C83.8384 66.6834 83.0847 67.0116 82.6227 66.58L69.769 54.5764H28.2828C26.0514 54.5764 24.2424 52.765 24.2424 50.5305V4.04596C24.2424 1.81144 26.0514 0 28.2828 0H95.9596C98.191 0 100 1.81144 100 4.04596V50.5305C100 52.765 98.191 54.5764 95.9596 54.5764H83.8384V66.0507Z" fill="black"/>
      <path d="M50.505 27.3102C50.505 29.5447 48.6961 31.3562 46.4646 31.3562C44.2332 31.3562 42.4242 29.5447 42.4242 27.3102C42.4242 25.0757 44.2332 23.2642 46.4646 23.2642C48.6961 23.2642 50.505 25.0757 50.505 27.3102Z" fill="black"/>
      <path d="M66.6667 27.3102C66.6667 29.5447 64.8577 31.3562 62.6263 31.3562C60.3948 31.3562 58.5859 29.5447 58.5859 27.3102C58.5859 25.0757 60.3948 23.2642 62.6263 23.2642C64.8577 23.2642 66.6667 25.0757 66.6667 27.3102Z" fill="black"/>
      <path d="M82.8283 27.3102C82.8283 29.5447 81.0193 31.3562 78.7879 31.3562C76.5564 31.3562 74.7475 29.5447 74.7475 27.3102C74.7475 25.0757 76.5564 23.2642 78.7879 23.2642C81.0193 23.2642 82.8283 25.0757 82.8283 27.3102Z" fill="black"/>
    </g>
  </svg>
</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconChat",
        mixins: [icon_config],
    }
</script>

<style scoped>

</style>
